//CSS
import "/node_modules/bootstrap/dist/css/bootstrap.css"
import "/node_modules/@fortawesome/fontawesome-free/css/all.css"
import "/node_modules/lite-youtube-embed/src/lite-yt-embed.css"

import "./../css/styles.css"


// JS
import * as bootstrap from 'bootstrap';

import "/node_modules/jquery-easing/jquery.easing.1.3"
import "/node_modules/jquery-lazy/jquery.lazy"
import Cookies from 'js-cookie'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/pt-br'

import "/node_modules/lite-youtube-embed/src/lite-yt-embed.js"

/* Navbar Scripts */
// jQuery to collapse the navbar on scroll
// $(window).on('scroll load', function() {
//     if ($(".navbar").offset().top > 20) {
//         $(".fixed-top").addClass("top-nav-collapse");
//     } else {
//         $(".fixed-top").removeClass("top-nav-collapse");
//     }
// });

// jQuery for page scrolling feature - requires jQuery Easing plugin
$(function() {
    $(document).on('click', 'a.page-scroll', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top
        }, 600, 'easeInOutExpo');
        event.preventDefault();
    });

    

});

/* Back To Top Button */
// create the back to top button
$('body').prepend('<a href="body" class="back-to-top page-scroll">Back to Top</a>');
var amountScrolled = 700;
$(window).scroll(function() {
    if ($(window).scrollTop() > amountScrolled) {
        $('a.back-to-top').fadeIn('500');
    } else {
        $('a.back-to-top').fadeOut('500');
    }
});

// closes the responsive menu on menu item click
$(".navbar-nav li a").on("click", function(event) {
    if (!$(this).parent().hasClass('dropdown'))
        $(".navbar-collapse").collapse('hide');
});

//Human readable date
dayjs.extend(relativeTime)
dayjs.locale('pt-br')

$(".time strong").each(function(i, e) {
    var time = dayjs().to(dayjs.unix($(this).text()));
    $(e).text(time);
})

//Lazy loader
$('.lazy').lazy();

//LGPD
if (Cookies.get('lgpd') != "true") {
    $('#lgpd-bar').removeClass('invisible');
    $('body').on('click', "#lgpd-btn", function() {
        Cookies.set('lgpd', 'true', { expires: 365 })
        $('#lgpd-bar').addClass('invisible');
    });
}

//Exit intent popup
var myModal = new bootstrap.Modal(document.getElementById('exit-pop'))

// Wrap the setTimeout into an if statement
if (!Cookies.get('subscribed') && !Cookies.get('exitIntentShown')) {
        setTimeout(() => {
        document.addEventListener('mouseout', mouseEvent);
    }, 1000);
}

const mouseEvent = e => {
    const shouldShowExitIntent = 
        !e.toElement && 
        !e.relatedTarget &&
        e.clientY < 10;

    if (shouldShowExitIntent) {
        document.removeEventListener('mouseout', mouseEvent);
        myModal.show();
        
        // Set the cookie when the popup is shown to the user
        Cookies.set('exitIntentShown', true, { expires: 30 });
    }
};